import Head from 'next/head'
import Image from 'next/image'
import Layout from '../components/layout'
import { useRouter } from 'next/router'
import React, { useState, useRef } from 'react'
import Container from '../components/Container';
import { 
  Heading, 
  Text, 
  Flex, 
  Link,
  Box, 
  VStack, 
  HStack,
  Button,
  useColorModeValue,
  Alert,
  AlertIcon,
  AlertTitle,
  AlertDescription,
  CloseButton,
  Container as ChakraContainer,
  FormControl,
  FormLabel,
  FormErrorMessage,
  Input,
  InputGroup,
  InputRightElement,
  Stack,
  Center,
  SimpleGrid,
  GridItem
} from "@chakra-ui/react"
import useSWR from "swr";
import PaginatedList from '../components/VariantPaginatedList';
import { Formik, Form, Field } from "formik";
import * as Yup from 'yup';
import NextLink from 'next/link'
import { FiChevronRight } from "react-icons/fi";

const fetcher = (url, token) =>
  fetch(url, {
    method: 'GET',
    headers: new Headers({ 'Content-Type': 'application/json', token }),
    credentials: 'same-origin',
  }).then((res) => res.json())

function VariantsData() {
  const router = useRouter();
  const ref = useRef()
  // console.log(router);
  const { data, error, isValidating } = useSWR(`/api/variants${router.asPath}`,fetcher, {dedupingInterval: 15000, initialData: ref.current ? ref.current : undefined, revalidateOnFocus: false })
  
    if (data) {
      ref.current = data;
    }

  return (
    <PaginatedList router={router} data={data} error={error} isValidating={isValidating} />
  );
}

export default function Home(props, {fallback}) {
  // console.log(props);
  const router = useRouter()
  const [pageIndex, setPageIndex] = useState(1);
  const [emailChangeProceedOther, setEmailChangeProceedOther] = useState()
  const [emailChangeSuccessful, setEmailChangeSuccessful] = useState()
  const [searchQuery, setSearchQuery] = useState('');
  const [show, setShow] = useState(false)
  const handleClick = () => setShow(!show)

  // useEffect(() => {
  //   if (router.query.search) {
  //     setSearchQuery(router.query.search)
  //   }
  //   else {
  //     setSearchQuery('')
  //   }

  // }, [router])


  const onSubmit = evt => {
    evt.preventDefault();
    if (searchQuery != '') {
      router.push({
        pathname: '/variants',
        query: { search: encodeURI(searchQuery) },
    });
    }
    else {
      router.push({
        pathname: '/variants',
    });
    }
    
  };
  
  const bg = useColorModeValue("white", "darkGray.900")
  const inputBg = useColorModeValue("gray.100", "neutral.1000")
  const inputText = useColorModeValue("black", "white")
  const borderColor = useColorModeValue("gray.200", "neutral.900")
  const inputPlaceholderColor = useColorModeValue("darkGray.800", "darkGray.500")
  const buttonColor = useColorModeValue('gray.600', 'gray.600');
  const buttonBG = useColorModeValue('cyan.300', 'cyan.300');

  const handleSignUp = async (values, actions) => {
    // console.log(values);
    setError('')
    setLoading(true)
    const { error:signUpError, user:signUpUser, session: signUpSession } = await supabase.auth.signUp({
    email: values.email,
    password: values.password,
    })


    // console.log(signUpError);
    if (signUpError) setError(signUpError.message)
    else setSignupView('confirm_email')
    setLoading(false)
}

  const SignUpSchema = Yup.object().shape({
    email: Yup
        .string()
        .email()
        .required("Please enter your email address"),
    password: Yup
        .string()
        .required("Please enter your new password")
        .matches(
        /^.*(?=.{8,})((?=.*[!@#$%^&*()\-_=+{};:,<.>]){1})(?=.*\d)((?=.*[a-z]){1})((?=.*[A-Z]){1}).*$/,
        "Password must contain at least 8 characters, one uppercase, one number and one special case character"
        ),
    confirmPassword: Yup
        .string()
        .required("Please confirm your new password")
        .when("password", {
        is: password => (password && password.length > 0 ? true : false),
        then: Yup.string().oneOf([Yup.ref("password")], "Password doesn't match")
        })
});
  
  const renderForm = (formikBag) => (
    <Form>
    <Stack spacing={5}>
    <Field name="email">
        {({ field, form }) => (
          <FormControl isInvalid={form.errors.email && form.touched.email} isRequired>
            <FormLabel htmlFor="email" id="signup-email-label">Email Address</FormLabel>
            <InputGroup size="md">
                <Input
                {...field}
                id="email"
                placeholder="Enter your email address"
                />
                </InputGroup>
            <FormErrorMessage>{form.errors.email}</FormErrorMessage>
          </FormControl>
        )}
      </Field>
      <Field name="password">
        {({ field, form }) => (
          <FormControl isInvalid={form.errors.password && form.touched.password} isRequired>
            <FormLabel htmlFor="password" id="signup-password-label">New password</FormLabel>
            <InputGroup size="md">
                <Input
                {...field}
                id="password"
                type={show ? "text" : "password"}
                placeholder="Enter password"
                autoComplete="on"
                />
                <InputRightElement width="4.5rem">
                <Button h="1.75rem" size="sm" onClick={handleClick}>
                {show ? "Hide" : "Show"}
                </Button>
                    </InputRightElement>
                </InputGroup>
            <FormErrorMessage>{form.errors.password}</FormErrorMessage>
          </FormControl>
        )}
      </Field>
        <Field name="confirmPassword">
        {({ field, form }) => (
          <FormControl isInvalid={form.errors.confirmPassword && form.touched.confirmPassword} isRequired>
            <FormLabel htmlFor="confirmPassword" id="login-confirm-password-label">Confirm password</FormLabel>
            <InputGroup size="md">
                <Input
                {...field}
                id="confirmPassword"
                type={show ? "text" : "password"}
                placeholder="Enter password again"
                autoComplete="on"
                />
                </InputGroup>
            <FormErrorMessage>{form.errors.confirmPassword}</FormErrorMessage>
          </FormControl>
        )}
      </Field>
      </Stack>
      <Button
    mt={10}
    bg={useColorModeValue('black', 'gray.700')}
    color="white"
    border= '1px solid'
    borderColor={useColorModeValue('black', 'gray.800')}
    minW='100%'
    _hover={{
      bg: 'none',
      border: '1px solid',
      borderColor: useColorModeValue('black', 'gray.600'),
      color: useColorModeValue('black', 'gray.50'),
    }}
    isLoading={formikBag.isSubmitting}
    type="submit"
    >
        Sign Up
      </Button>
    </Form>
);

  const onChange = evt => setSearchQuery(evt.target.value);
  return (
    <Layout>
      <Head>
      <title>Biocodify</title>
      <meta name="description" content="Simplest way to analyze DNA. Connect your data with genetic databases, see what's important, easily navigate in your data and make your own research. All in one place."></meta>
      <meta name="robots" content="index, follow"></meta>
      </Head>
      <Container>
        {emailChangeProceedOther && (
      <Alert status='success'>
      <AlertIcon />
      <Box flex='1'>
        <AlertTitle>Almost there!</AlertTitle>
        <AlertDescription display='block'>
        Confirmation link accepted. Please proceed to confirm link sent to the other email.
        </AlertDescription>
      </Box>
      <CloseButton position='absolute' right='8px' top='8px' onClick={() => setEmailChangeProceedOther(false)}/>
      </Alert>)
      }
      <Box>
      <Stack
        align={'center'}
        spacing={{ base: 8, md: 10 }}
        py={{ base: 20, md: 20 }}
        px={{ base: 5, md: 20 }}
        direction={{ base: 'column', md: 'row' }}
      >
        <Stack flex={1} spacing={{ base: 5, md: 8 }} align="center">
          <Heading as="h1" fontSize="6xl" fontWeight='bold'  align={["center"]} bgGradient='linear(to-l, teal.200, cyan.500)' bgClip='text'>Simplest way to analyze DNA</Heading>
          <Text as="h2" fontSize="xl" fontWeight='medium' maxW="700px" align={["center"]}>Connect your data with genetic databases, see what's important, easily navigate in your data and make your own research. <br />All in one place.</Text>
          <HStack>
          <NextLink href="/signup" passHref><Button size="lg" fontSize={['sm', 'md']} color={buttonColor} bg={buttonBG} borderRadius={30} _hover={{bg: "gray.600", color: "gray.50"}}>Start now</Button></NextLink>
          <NextLink href="/demo" passHref><Button size="lg" fontSize={['sm', 'md']} color={useColorModeValue('gray.600', 'white')} bg={useColorModeValue('gray.100', 'gray.700')} borderRadius={30} _hover={{bg: "gray.600", color: "gray.50"}}>Try the demo</Button></NextLink>
          </HStack>
        </Stack>
        </Stack>
        <Box align="center" w='100%' py="10px" px={5}>
          <Text color="gray.400" fontWeight={'600'}>Supported Files</Text>
          <Center>
          <HStack spacing="24px">
            <Box>
          <Image src="/images/23andme-logo.svg" height={70} width={70} />
          </Box>
          <Box>
          <img src="/images/ancestry-logo.svg" height="100px" width="110px"/>
          </Box>
          <Box><Image src="/images/ftdna-logo.svg" height={70} width={70} /></Box>
          <Box>
          <img src="/images/nebula-genomics-logo.svg" height="100px" width="110px"/>
          </Box>
          <Box>
          <img src="/images/dante-labs-logo.png" height="20px" width="65px"/>
          </Box>
          <Box>
          <img src="/images/sequencing-logo.svg" height="100px" width="110px"/>
          </Box>
          </HStack>
          </Center>
          <Box my={5}><Text fontWeight={'medium'}>Not seeing your file?</Text><Text fontSize='md'>Contact us, we would love to support!</Text></Box>
          </Box>
          <Box bg="gray.800" align={'center'} py="10">
            <Stack spacing={5}>
            <Heading color="cyan.400">Start With Uploading, Never Stop Exploring</Heading>
            <Heading color="gray.200" fontSize={'16'}>Get started with 3 easy steps</Heading>
            </Stack>
          </Box>
          <SimpleGrid columns={[1, 3]} spacing='40px' bg="gray.800" align="center" pb={10}>
            <Box>
              <Text color="white" fontSize={'7xl'} fontWeight={700}>1</Text>
              <Text color="white" fontSize={'xl'} fontWeight={600}>Upload</Text>
              <Text color="white" fontSize={'md'} fontWeight={400}>Upload your file</Text>
            </Box>
            <Box>
              <Text color="white" fontSize={'7xl'} fontWeight={700}>2</Text>
              <Text color="white" fontSize={'xl'} fontWeight={500}>Analyzing</Text>
              <Text color="white" fontSize={'md'} fontWeight={400}>We will process your file, and analyze your data</Text>
            </Box>
            <Box>
              <Text color="white" fontSize={'7xl'} fontWeight={700}>3</Text>
              <Text color="white" fontSize={'xl'} fontWeight={500}>Explore</Text>
              <Text color="white" fontSize={'md'} fontWeight={400}>Explore your results and research</Text>
            </Box>
            </SimpleGrid>
          <Stack
            align={'center'}
            spacing={{ base: 8, md: 10 }}
            py={{ base: 20, md: 10 }}
            px={{ base: 5, md: 20 }}
            direction={{ base: 'column', md: 'row' }}
            bg="white"
          >
            <Box maxW={'600px'}>
              <Stack spacing={0}>
              <Box mb={5}>
                <Text fontSize="sm" color="cyan.500" fontWeight={'700'}>VAST RESOURCES</Text>
                <Heading fontSize="4xl" as="h2" color="black">1 Million Variants and Counting</Heading>
                </Box>
                <Text fontSize="xl" color="black" fontWeight={'500'}>Biocodify integrates various scientific sources to interpret your data. As more information added to these sources, your data will be updated accordingly. </Text>
              </Stack>
            </Box>
          <Box>
          <Image src="/images/variants.svg" height={400} width={700} />
          </Box>
          </Stack>
          <Stack
            align={'center'}
            spacing={{ base: 8, md: 10 }}
            py={{ base: 20, md: 10 }}
            px={{ base: 5, md: 20 }}
            direction={{ base: 'column', md: 'row' }}
            bg="white"
          >
          <Box>
          <Image src="/images/variants-and-more.svg" height={500} width={700} />
          </Box>
          <Box maxW={'500px'}>
              <Stack spacing={5}>
                <Box mb={5}>
                <Text fontSize="sm" color="cyan.500" fontWeight={'700'}>INTEGRATED DATA</Text>
                <Heading fontSize="4xl" as="h2" color="black">Variants Have Genes, Conditions and More</Heading>
                </Box>
                <Text fontSize="xl" color="gray.700" fontWeight={'500'}>Variants are always combined with their genes and phenotypes/conditions. See all the variants in a gene with one click, filter by significance, jump to their conditions. Super easy to navigate.</Text>

                <HStack>
                <NextLink href="/signup" passHref><Button size="lg" fontSize={['sm', 'md']} color={buttonColor} bg={buttonBG} borderRadius={30} _hover={{bg: "gray.600", color: "gray.50"}}>Start now</Button></NextLink>
                <NextLink href="/demo" passHref><Button size="lg" fontSize={['sm', 'md']} color={useColorModeValue('black', 'white')} bg={useColorModeValue('gray.100', 'gray.700')} borderRadius={30} _hover={{bg: "gray.600", color: "gray.50"}}>See the demo</Button></NextLink>
                </HStack>
              </Stack>
            </Box>
          </Stack>
          <Stack
            align={'center'}
            spacing={{ base: 8, md: 10 }}
            py={{ base: 20, md: 20 }}
            px={{ base: 5, md: 20 }}
            direction={{ base: 'column', md: 'row' }}
            bg="gray.50"
          >

          <Box maxW={'600px'}>
              <Stack spacing={0}>
                <Box mb={5}>
                <Text fontSize="sm" color="cyan.500" fontWeight={'700'}>ALWAYS UPDATED</Text>
                <Heading fontSize="4xl" as="h2" color="black">Science is Always Updated <br/>So are You</Heading>
                </Box>
                <Text fontSize="xl" color="gray.700" fontWeight={'500'}>New information is in nature of science. Every day a new discovery is made. Scientific sources are always updated. Interpretation of your data may change in time. That's why it's important to stay updated with new information. <br />Biocodify does that for you, automatically. As the new data arrive or existing data change, your results will be updated as well.</Text>
              </Stack>
            </Box>
            <Box>
            <Image src="/images/updated-dna.svg" height={400} width={700} />
            </Box>
          </Stack>
          <Stack
            align={'center'}
            spacing={{ base: 8, md: 10 }}
            py={{ base: 20, md: 20 }}
            px={{ base: 5, md: 20 }}
            direction={{ base: 'column', md: 'row' }}
            bg="white"
          >
            <Box>
            <Image src="/images/privacy.svg" height={300} width={500} />
            </Box>
          <Box maxW={'600px'}>
              <Stack spacing={0}>
                <Box mb={5}>
                <Text fontSize="sm" color="cyan.500" fontWeight={'700'}>MINDING OUR BUSINESS</Text>
                <Heading fontSize="4xl" as="h2" color="black">We are not Interested in You</Heading>
                </Box>
                <Text fontSize="xl" color="gray.700" fontWeight={'500'}>Privacy is important. Biocodify is designed to express it in easiest way: <br/><br/><u><b>We don't ask any personal information, period</b></u>. <br/><br/>It's up to you what to provide, so you can even upload made up DNA data. We will interpret that as well.</Text>
              </Stack>
            </Box>

          </Stack>        
        </Box>
        <SimpleGrid bgGradient='linear(to-l, teal.200, cyan.500)' py="20" align="center">
          <Center>
          <HStack spacing="6">

          <Text color="white" fontWeight={'600'} fontSize={'3xl'}>Ready to get started?</Text>
          <NextLink href="/signup" passHref>
            <Button size="lg" color={buttonColor} bg="gray.100" borderRadius={30} _hover={{bg: "gray.600", color: "gray.50"}}>Sign up now</Button></NextLink>
          </HStack>
          </Center>
          </SimpleGrid>
  </Container>

    </Layout>
  )
}