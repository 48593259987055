import useSWR, { SWRConfig } from "swr";
import React, { useRef, useState, useEffect } from 'react';
import { useRouter } from 'next/router';
import NProgress from 'nprogress';
import Pagination from './Pagination';
import axios from 'axios';
import NextLink from 'next/link'
import { Flex, Stack, VStack, Box, Center, Link, Skeleton, SkeletonText, Badge, Text, useColorModeValue } from "@chakra-ui/react"
import VariantCard from './VariantCard'
import { Spinner } from "@chakra-ui/react"
import Image from "next/image";

const fetcher = url => axios.get(url).then(res => res.data)

export default function PaginatedList (props) {
  // console.log("PROPS:");
  // console.log(props.data);

    if (props.error) {
      return (
        <>
        <VStack marginRight={[0, 0, 0, 0, 180]} my={[50]}>
        <Image src="/images/gene-error.svg" height={60} width={60} />
        <Text fontSize="lg" fontWeight="normal">Something went terribly wrong...</Text>
        </VStack>
        </>
      )
    }
  
    if (!props.data) {
      return (
        <>
        <Box>
        <Center>
        <Spinner color="cyan.500" size="lg" thickness="3px"/>
        </Center>
        </Box>
      </>
      )
    }

    const results = props.data.results.length == 0 ? undefined : props.data.results
    const showingTextColor = useColorModeValue("gray.500", "neutral.900")
    {props.isValidating ? NProgress.start() : NProgress.done()}

      if (!results) {
        return (
          <>
          <VStack marginRight={[0, 0, 0, 0, 180]}>
          <Image src="/images/gene-not-found.svg" height={120} width={120} />
          <Text fontSize="lg" fontWeight="normal">No variant found</Text>
          </VStack>
          </>
          )
      }
      else {
      return (
        <>
        <Box>
        <Stack spacing={4}>
          {props.data.results.map((variant, index) => (
            <VariantCard variant={variant} key={index}/>
            ))}
            </Stack>
            <Box mt={5}>
            {/* <Text fontSize="xs" fontWeight="bold" color={showingTextColor}>SHOWING {((props.router.query.page-1)*10)+1} - {(((props.router.query.page-1)*10)+1) + props.data.results.length -1} OF {props.data.count} RESULTS {props.router.query.search ? `FOR "${props.router.query.search.toUpperCase()}"` : null}</Text> */}
            </Box>
        <Box mt={10}>
        <Pagination total={props.data.totalQuery}  sizes={[10, 25, 50]}/>
        </Box>
        </Box>
        </>
      )
    }
}