import NextLink from 'next/link'
import { Link, Box, Text, Flex, Stack, HStack, Divider, Spacer, VStack, Badge, Wrap, WrapItem, Heading, LinkBox, LinkOverlay, useColorModeValue } from '@chakra-ui/react'

export default function VariantCard(props) {
    // console.log("VARIANT CARD PROPS:");
    // props.variant.id == 594846 && console.log(props.variant.clinvar)
    // console.log(props);
    // console.log(props.variant.clinvar);
    const bg = useColorModeValue("white", "")
    const color = useColorModeValue("black", "neutral.200")
    const borderColor = useColorModeValue("gray.100", "neutral.900")
    var clinical_significances = []
    props.variant.clinvar && props.variant.clinvar.map(sig => sig.clinical_significance.map(sig_i => clinical_significances.push(sig_i)))
    var clinical_significances = Array.from(new Set(clinical_significances))
    
    function cln_sig_color(param) {
        // console.log('cln sig color:');
        // console.log(param);
        if (param.toUpperCase().includes('benign'.toUpperCase()) && param.toUpperCase().includes('pathogenic'.toUpperCase())) {
            return 'gray'
        }
        else if (param.toUpperCase().includes('benign'.toUpperCase())) {
            return 'green'
        }
        else if (param.toUpperCase().includes('pathogenic'.toUpperCase())) {
            return 'red'
        }
        else if (!param.toUpperCase().includes('benign'.toUpperCase()) && !param.toUpperCase().includes('pathogenic'.toUpperCase())) {
            return 'gray'
        }
    };

    return (
    
        <LinkBox as="article" w="100%" p={3} bg={bg} color={color} borderRadius={15} border="1px" borderColor={borderColor}  overflow='hidden'>

        <Box flexShrink={0}>
        <HStack>
        <Box>
        <Box
            mt="1"
            fontWeight="semibold"
            as="h4"
            lineHeight="tight"
            isTruncated
            align="left"
        >
             <NextLink href={`/variants/${props.variant.slug}`} passHref>
                 <LinkOverlay>
            <Heading as="h2" size="md">rs{props.variant.rsid}</Heading>
            </LinkOverlay>
            </NextLink>
        </Box>
        {/* {props.variant.clinvar.length > 0 ?
        <Box>
            <Text fontSize="sm" fontWeight="normal">{props.variant.clinvar[0].name}</Text>
        </Box>
        : null} */}
        </Box>
        <Spacer />
        <Box align="right">
        {/* <Text color="gray.400" fontSize={11} fontWeight="bold">
        {props.variant.variant_type.toUpperCase()}
        </Text> */}
        <VStack spacing="5px" align="right">
        {clinical_significances.length > 0 && clinical_significances.map((clinvar, index) => <Box><Badge ml="0" key={index} colorScheme={cln_sig_color(clinvar)} borderRadius="10" px={3} py={1} textTransform="capitalize">{clinvar}</Badge></Box>)}
        </VStack>
        </Box>
        </HStack>
        {/* {props.variant.clinvar.length > 0 ?
        <Badge ml="0" colorScheme={cln_sig_color(props.variant.clinvar[0].cln_sig)} borderRadius="lg" pl={3} pr={3} pt={0.2} pb={0.2} textTransform="capitalize">{props.variant.clinvar[0].cln_sig}</Badge>
        : null} */}
        
        {props.variant.genes.length > 0 ? 
        <Box mt={10}>
        <Divider orientation="horizontal" mt={5} colorScheme="red" borderColor={borderColor}/>

        <Box mt={2}>
            <Heading as="h6" size="xs" color="#849294" fontWeight="bold" fontSize="12px">GENES</Heading>
        </Box>
        
        {props.variant.genes.length > 3 ?
        <Wrap mt={2}>
        {props.variant.genes.slice(0,3).map((gene, index) => 
        <WrapItem key={index}>
              <Badge ml="0" colorScheme="gray" borderRadius="12" px={3} py={1} textTransform="capitalize">
              {gene.symbol}
            </Badge>
            </WrapItem>
        )}
        
        <Heading size={"xs"} fontSize={"12"} ml="0" colorScheme="gray" borderRadius="lg" pl={3} pr={3} pt={0.5} textTransform="capitalize">+{props.variant.genes.length - 3} MORE</Heading>
        </Wrap>
        : 
        <Wrap mt={2}>
        {props.variant.genes.map((gene, index) => 
        <WrapItem key={index}>
              <Badge ml="0" colorScheme="gray" borderRadius="12" px={3} py={1} textTransform="capitalize">
              {gene.symbol}
            </Badge>
            </WrapItem>
        )}
        </Wrap>
        }
        </Box>
        : null}
         <Divider orientation="horizontal" mt={5} colorScheme="red" borderColor={borderColor}/>

        <Box mt={2}>
            <Heading as="h6" size="xs" color="#849294" fontWeight="bold" fontSize="12px">PHENOTYPES</Heading>
        </Box>
        {props.variant.phenotypes.length > 0 ?
        <Box>
        {props.variant.phenotypes.length > 3 ?
        <Wrap mt={2}>
        {props.variant.phenotypes.slice(0,3).map((phenotype, index) => 
        <WrapItem key={index}>
              <Badge ml="0" colorScheme="cyan" borderRadius="12" px={3} py={1} textTransform="capitalize">
              {phenotype.name}
            </Badge>
            </WrapItem>
        )}
        <Heading size={"xs"} fontSize={"12"} ml="0" colorScheme="gray" borderRadius="12" px={3} py={1} textTransform="capitalize">+{props.variant.phenotypes.length - 3} MORE</Heading>
        </Wrap>
        : 
        <Wrap mt={2}>
        {props.variant.phenotypes.length > 0 && props.variant.phenotypes.map((phenotype, index) =>
        <WrapItem key={index}>
            {phenotype && phenotype.name ?
              <Badge ml="0" colorScheme="cyan" borderRadius="12" px={3} py={1} textTransform="capitalize">
              {phenotype.name}
            </Badge>
            : <Text fontSize={11} mt={1} fontWeight='bold' color="#849294" >NOT AVAILABLE</Text>}
            </WrapItem>
        )}
        </Wrap>
        }
        </Box>
        : <Text fontSize={11} mt={1} fontWeight='bold' color="#849294" >NOT AVAILABLE</Text>}
        </Box>
        </LinkBox>
    )
}